import * as yup from "yup";
// import { isCpf } from "@pixter/utils/lib/validators";

export default yup.object({
  numero_os: yup
    .string()
    .matches(/^[0-9]{7}-[0-9]{2}$/, "OS inválida")
    .required("Campo obrigatório"),
  cpf: yup
    .string()
    // .matches(/^([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}|[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2})$/, "Documento inválido")
    .matches(/^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/, "CPF inválido")
    // .test("test-cpf", "CPF inválido", (value) => isCpf(value))
    .required("Campo obrigatório"),
});
