import { reportChat } from "services/api";
import { analyticsEvent } from "../analytics";

export const chatEvent = async (params) => {

  // open new window: chat
  window.open(process.env.REACT_APP_CHAT_URL);

  // analytics
  analyticsEvent({
    action: 'click',
    category: 'link',
    label: 'Chat'
  });

  // sheet
  const {uuid, origin} = params;
  
  const response = await reportChat({
    uuid,
    origin
  });

  // console.log({response});
  return response;

}